import {Navigate, Outlet, useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import GroupIcon from 'src/images/team-upgrade-payments-image.png'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {
  RoutableTab,
  RoutableTabPanel,
  RoutableTabs,
} from 'src/components/RoutableTabs'
import {ManagerSummaryModal} from 'src/components/ManagerSummaryModal'
import {useManagerRole} from 'src/components/ManageRoleProvider'

import {
  CollectionAccessPanel,
  CollectionGroupPagePanel,
  CollectionTotalsSummaryPanel,
  ShareCollectionPanel,
} from './CollectionManagePanels'

const CollectionManagePage = () => {
  const media = WebUI.useMedia()
  const urlParams = useParams()
  const {data: session} = api.auth.session.useQuery()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const growlActions = WebUI.useGrowlActions()
  const [managerRole] = useManagerRole()

  if (collection?.status === 'draft') {
    return <Navigate to="../details" />
  }

  return (
    <div className="mx-auto flex w-full max-w-[min(100%,1320px)] flex-col gap-6 overflow-y-auto px-4 py-8">
      <div className="flex flex-col">
        <WebUI.Heading>Manage</WebUI.Heading>
        {managerRole && (
          <WebUI.Text className="text-ds-sm">
            {managerRole.name}:{' '}
            <ManagerSummaryModal
              className="text-ds-sm capitalize"
              managerRole={managerRole}
            >
              {managerRole.permissions?.role}
            </ManagerSummaryModal>
          </WebUI.Text>
        )}
      </div>

      {!!collection && (
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex flex-col gap-4 sm:gap-10">
            <CollectionTotalsSummaryPanel collection={collection} />

            {!media.sm && (
              <div
                className={WebUI.cn(
                  'flex flex-col gap-3',
                  '[&_>_.Panel]:w-full',
                )}
              >
                <ShareCollectionPanel collection={collection} />

                <CollectionGroupPagePanel collection={collection} />

                {managerRole?.permissions?.role !== 'viewer' && (
                  <CollectionAccessPanel collection={collection} />
                )}
              </div>
            )}

            <RoutableTabs variant="underlined">
              <div className="flex grow flex-col gap-4">
                <div className="relative flex overflow-auto sm:block">
                  <div className="flex items-center gap-4 sm:block">
                    <WebUI.TabList
                      aria-label="Navigation"
                      className="[&_.TabList-underline]:bg-orange-500"
                    >
                      <RoutableTab to="payments">Payments</RoutableTab>
                      {collection.reportsAvailable.activeItemsCount > 0 && (
                        <RoutableTab to="items">Items</RoutableTab>
                      )}
                      {collection.reportsAvailable.activeFormsCount +
                        collection.reportsAvailable.activeSignupsCount >
                        0 && (
                        <RoutableTab to="forms-signups">
                          Forms & Sign Ups
                        </RoutableTab>
                      )}
                      {collection.payer_identify && (
                        <RoutableTab to="visitors">Visitors</RoutableTab>
                      )}
                      {collection.reportsAvailable.shippingPayments && (
                        <RoutableTab to="shipping">Shipping</RoutableTab>
                      )}
                    </WebUI.TabList>
                    {(!managerRole ||
                      managerRole.permissions
                        .address_book_and_message_center) && (
                      <LinkButton
                        variant="default"
                        className="sm:-translate-y-1/2 sm:absolute sm:top-1/2 sm:right-0"
                        to="message-center?mcp=send"
                      >
                        Send Message
                      </LinkButton>
                    )}
                  </div>
                </div>
                <RoutableTabPanel>
                  <Outlet />
                </RoutableTabPanel>
              </div>
            </RoutableTabs>
          </div>

          <div
            className={WebUI.cn(
              'flex flex-col gap-3 xl:w-[min(100%,320px)]',
              '[&_>_.Panel]:w-full',
            )}
          >
            {media.sm && (
              <>
                <ShareCollectionPanel collection={collection} />

                <CollectionGroupPagePanel collection={collection} />

                {managerRole?.permissions?.role !== 'viewer' && (
                  <CollectionAccessPanel collection={collection} />
                )}
              </>
            )}

            <WebUI.Panel className="gap-5 p-6" as={WebUI.VStack}>
              <WebUI.Heading className="text-gray400 uppercase" as="h6">
                Reports
              </WebUI.Heading>

              <div className="flex flex-col">
                <WebUI.Button
                  className="text-ds-sm"
                  variant="link"
                  onClick={async () => {
                    const res = await api.exports.paymentsXlsx.fetch({
                      pathParams: {
                        // biome-ignore lint/style/noNonNullAssertion:
                        tabId: urlParams.collection!,
                      },
                      body: {},
                    })
                    if (res.file_url) {
                      window.location.href = res.file_url
                    }

                    growlActions.show('success', {
                      title: 'Your report is in progress',
                      body:
                        res.message ||
                        'Your report was downloaded to your device.',
                    })
                  }}
                >
                  Collection Summary (xlsx)
                </WebUI.Button>
                <WebUI.Text className="font-light text-ds-xs text-gray800">
                  All payment and item-related details
                </WebUI.Text>
              </div>

              <WebUI.Separator variant="primary" />

              <div className="flex flex-col">
                <Link
                  className="text-ds-sm"
                  variant="primary"
                  target="_blank"
                  to={`/pdf/collection/${urlParams.collection}/orders-report`}
                >
                  Printable Payment Summaries (pdf)
                </Link>
                <WebUI.Text className="font-light text-ds-xs">
                  Great for invoices and packing slips
                </WebUI.Text>
              </div>

              {collection?.payer_identify && (
                <>
                  <WebUI.Separator variant="primary" />
                  <div className="flex flex-col">
                    <WebUI.Button
                      className="text-ds-sm"
                      variant="link"
                      onClick={async () => {
                        const res = await api.exports.visitorsXlsx.fetch({
                          pathParams: {
                            // biome-ignore lint/style/noNonNullAssertion:
                            tabId: urlParams.collection!,
                          },
                        })
                        if (res.file_url) {
                          window.location.href = res.file_url
                        }
                        growlActions.show('success', {
                          title: 'Your report is in progress',
                          body:
                            res.message ||
                            'Your report was downloaded to your device.',
                        })
                      }}
                    >
                      Visitor Summary (xlsx)
                    </WebUI.Button>
                    <WebUI.Text className="font-light text-ds-xs text-gray800">
                      Everyone who visited your collection
                    </WebUI.Text>
                  </div>
                </>
              )}

              <WebUI.Separator variant="primary" />

              <div className="flex flex-col">
                <Link
                  className="text-ds-sm"
                  variant="primary"
                  to={{
                    pathname: '/reports',
                    search: `?collectionIds=${collection.id}`,
                  }}
                >
                  Top Payers & Items
                </Link>
                <WebUI.Text className="font-light text-ds-xs">
                  See top-ranking payers and items
                </WebUI.Text>
              </div>
            </WebUI.Panel>

            {managerRole == null &&
            !!session &&
            !session.capabilities.subscribed_to_team ? (
              <WebUI.Panel className="flex flex-col items-start gap-5 p-6">
                <WebUI.Heading
                  className="max-w-[230px] font-accent font-bold text-ds-md"
                  as="h3"
                >
                  <img
                    className="float-left mr-[15px]"
                    src={GroupIcon}
                    width="74"
                    height="92"
                    alt="Cheddar group offer"
                  />
                  Account-wide reporting is a click away
                </WebUI.Heading>
                <WebUI.Text className="font-light text-ds-sm">
                  Tap into collecting trends{' '}
                  <span className="font-bold">across your entire account</span>{' '}
                  with our Team plan
                </WebUI.Text>
                <WebUI.RadioGroup
                  className={
                    '[&_>_.Radio_>_.Radio-icon]:mt-1 [&_>_.Radio_>_.Radio-icon]:h-[1em] [&_>_.Radio_>_.Radio-icon]:w-[1em] [&_>_.Radio_>_.Radio-icon]:self-start [&_>_.Radio_>_.Radio-icon_>_Radio-checkIcon]:text-[0.75em] [&_>_.Radio_>_.Radio-text]:w-[178px]'
                  }
                  size="compact"
                >
                  <WebUI.Radio checked>
                    See top-trending payers and collections
                  </WebUI.Radio>
                  <WebUI.Radio checked>
                    Access reporting across all collections
                  </WebUI.Radio>
                  <WebUI.Radio checked>
                    Create and save reports with custom filters
                  </WebUI.Radio>
                </WebUI.RadioGroup>

                <LinkButton variant="primary" to="i/plans">
                  Upgrade to Team
                </LinkButton>
              </WebUI.Panel>
            ) : null}
          </div>
        </div>
      )}
    </div>
  )
}

export default CollectionManagePage
