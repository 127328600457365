import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import React from 'react'

export interface CollectionPaymentsStatsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading: string
  paymentTotal: number
}

const CollectionPaymentsStats = ({
  className,
  heading,
  paymentTotal,
  ...restProps
}: CollectionPaymentsStatsProps) => (
  <div className={WebUI.cn('flex flex-col gap-2', className)} {...restProps}>
    <WebUI.Heading className="text-gray400 uppercase" as="h6">
      {heading}
    </WebUI.Heading>
    <p className="font-light text-gray800 text-h-6">
      {Util.formatAmount(paymentTotal)}
    </p>
  </div>
)

export default CollectionPaymentsStats
